require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
//require("channels");
import $ from "jquery";
global.$ = jQuery;

require("bootstrap");

$(document).on("turbolinks:click", function (event) {
	if (event.target.getAttribute("href").charAt(0) === "#") {
		return event.preventDefault();
	}
});

function hideMenu() {
	document.getElementsByTagName("menu")[0].classList.remove("active-menu");
}

document.addEventListener("click", (event) => {
	if (event.target.className !== "toggler-btn") {
		hideMenu();
	}
});

let ready;
ready = function () {
	$("#hamburger").click(function () {
		document.getElementsByTagName("menu")[0].classList.toggle("active-menu");
	});

	$("#readMore1").click(function () {
		$("#more1").toggle();
	});

	$("#readMore2").click(function () {
		$("#more2").toggle();
	});
	$("#readMore3").click(function () {
		$("#more3").toggle();
	});
	$("#readMore4").click(function () {
		$("#more4").toggle();
	});
	$("#readMore5").click(function () {
		$("#more5").toggle();
	});
	$("#readMore6").click(function () {
		$("#more6").toggle();
	});
	$("#readMore7").click(function () {
		$("#more7").toggle();
	});
	$("#readMore8").click(function () {
		$("#more8").toggle();
	});
	$("#readMore9").click(function () {
		$("#more9").toggle();
	});
	$("#readMore10").click(function () {
		$("#more10").toggle();
	});
	$("#readMore11").click(function () {
		$("#more11").toggle();
	});
	$("#readMore12").click(function () {
		$("#more12").toggle();
	});
	$("#readMore13").click(function () {
		$("#more13").toggle();
	});
	$("#readMore14").click(function () {
		$("#more14").toggle();
	});
	$("#readMore15").click(function () {
		$("#more15").toggle();
	});
	$("#readMore16").click(function () {
		$("#more16").toggle();
	});
	$("#readMore17").click(function () {
		$("#more17").toggle();
	});
	$("#readMore18").click(function () {
		$("#more18").toggle();
	});
	$("#readMore19").click(function () {
		$("#more19").toggle();
	});
	$("#readMore20").click(function () {
		$("#more20").toggle();
	});
	$("#readMore21").click(function () {
		$("#more21").toggle();
	});
	$("#readMore22").click(function () {
		$("#more22").toggle();
	});
	$("#readMore23").click(function () {
		$("#more23").toggle();
	});
	$("#readMore24").click(function () {
		$("#more24").toggle();
	});
	$("#readMore25").click(function () {
		$("#more25").toggle();
	});
	$("#readMore26").click(function () {
		$("#more26").toggle();
	});
	$("#readMore27").click(function () {
		$("#more27").toggle();
	});
	$("#readMore28").click(function () {
		$("#more28").toggle();
	});
	$("#readMore29").click(function () {
		$("#more29").toggle();
	});
	$("#readMore30").click(function () {
		$("#more30").toggle();
	});
	$("#readMore36").click(function () {
		$("#more36").toggle();
	});
	$("#readMore37").click(function () {
		$("#more37").toggle();
	});
	$("#readMore38").click(function () {
		$("#more38").toggle();
	});
	$("#readMore39").click(function () {
		$("#more39").toggle();
	});
	$(".toggle").click(function () {
		$(this).toggleClass("active");
		$("#overlay").toggleClass("open");
	});

	$(".app a").click(function () {
		$(".overlay").removeClass("open");
	});

	$("#FreePlay").click(function () {
		$("#tabBox").addClass("activeCard1");
		$("#tabBox").removeClass("activeCard2");
		$("#tabBox").removeClass("activeCard3");
		$("#tabBox").removeClass("activeCard4");
	});
	$("#CashBack").click(function () {
		$("#tabBox").addClass("activeCard2");
		$("#tabBox").removeClass("activeCard1");
		$("#tabBox").removeClass("activeCard3");
		$("#tabBox").removeClass("activeCard4");
	});
	$("#ReduceBetting").click(function () {
		$("#tabBox").addClass("activeCard3");
		$("#tabBox").removeClass("activeCard1");
		$("#tabBox").removeClass("activeCard2");
		$("#tabBox").removeClass("activeCard4");
	});
	$("#ReferralBonus").click(function () {
		$("#tabBox").addClass("activeCard4");
		$("#tabBox").removeClass("activeCard1");
		$("#tabBox").removeClass("activeCard2");
		$("#tabBox").removeClass("activeCard3");
	});
};

document.addEventListener("turbolinks:load", ready);
